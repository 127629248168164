import cn from 'classnames';
import React, { Component } from 'react';
// TODO: rangeslider repo is no longer active. Replace.
import Slider from 'react-rangeslider';

import CateringUtils from '../../../modules/cateringUtils';

import 'react-rangeslider/lib/index.css';
import styles from './CostEstimator.module.postcss';
import mapFilterStyles from '../search/MapFilters.module.postcss';

const MEALS = ['Entree', 'Side', 'Drink', 'Dessert'];
const SLIDER_LABELS = { 50: '50', 500: '500' };

export default class CostEstimator extends Component {
  constructor(props) {
    super(props);
    this.onSelectMeal = this.onSelectMeal.bind(this);
    this.onChangeSlider = this.onChangeSlider.bind(this);
    this.renderMealButton = this.renderMealButton.bind(this);
    this.state = {
      meals: ['Side'],
      people: 60,
    }
  }

  onSelectMeal(e) {
    let meals = this.state.meals;
    const meal = e.target.value;

    if (this.state.meals.includes(meal)) {
      meals = this.state.meals.filter((m) => m !== meal);
    } else {
      meals = this.state.meals.concat(meal);
    }

    this.setState({ meals });
  }

  onChangeSlider(people) {
    this.setState({ people });
  }

  renderMealButton(id) {
    const classNames = cn(`ButtonSet ${mapFilterStyles.MapFilters_checkbox}`, {
      'ButtonSet--left': MEALS[0] === id,
      'ButtonSet--right': MEALS[MEALS.length - 1] === id,
      [mapFilterStyles.MapFilters_checkbox__checked]: this.state.meals.includes(id),
    });

    return (
      <label className={classNames} htmlFor={id} key={id}>
        <span>{id}</span>
        <input
          id={id}
          key={id}
          name="meal"
          onChange={this.onSelectMeal.bind(this)}
          type="checkbox"
          value={id}
        />
      </label>
    );
  }

  getEstimate() {
    const { low, high } = CateringUtils.calculateBudgetRange({
      food_options: this.state.meals,
      number_of_trucks: 1,
      number_of_people: this.state.people,
    });

    return `$${low} - $${high}`;
  }

  render() {
    const { title } = this.props;

    return (
      <section className={styles.CostEstimator + " NewSection"}>
        <h3>{title ? title : "Catering cost estimator"}</h3>
        <div className={styles.CostEstimator_contents}>
          <div>
            <p>About how many people are you expecting?</p>
            <Slider
              labels={SLIDER_LABELS}
              max={500}
              min={50}
              onChange={this.onChangeSlider}
              step={10}
              value={this.state.people}
            />
          </div>
          <div>
            <p>What are you serving?</p>
            <div>
              {MEALS.map((id) => this.renderMealButton(id))}
            </div>
          </div>
          <div>
            <p>Cost estimate</p>
            <p className={styles.CostEstimate_estimate}>{this.getEstimate()}</p>
          </div>
        </div>
        <a href="/catering/register" className="Button Button--blue u-mt1">
          Get started with a quote!
        </a>
      </section>
    );
  }
}
